<template>
	<main v-if="activity">
		<BaseHeader :title="activity.title" :routes="routes"/>
		<router-view/>
	</main>
</template>

<script>
export default {
	name: 'Activity',
	data() {
		return {
			routes: [
				{name: 'Generellt', to: `/activity/${this.$route.params.id}/general`, condition: true},
				{name: 'Anmälan', to: `/activity/${this.$route.params.id}/application`, condition: true},
				{name: 'Anmälningar', to: `/activity/${this.$route.params.id}/applications`, condition: true},
				{name: 'Närvaro', to: `/activity/${this.$route.params.id}/attendance`, condition: true},
				{name: 'Betalstatus', to: `/activity/${this.$route.params.id}/payment_status`, condition: true},
			],
		}
	},
	computed: {
		activity() {
			return this.$store.getters['ActivityStore/activity']
		},
	},
	created() {
		this.$store.dispatch('ActivityStore/view', this.$route.params.id)
	},	destroyed() {
		this.$store.commit('ActivityStore/CLEAR_ACTIVITY')
	},
}
</script>

<style scoped>
</style>